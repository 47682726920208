import { createAnimation } from '@ionic/angular';
import {
  query,
  animate,
  group,
  style,
  transition,
  trigger,
} from '@angular/animations';

const DURATION = 300;
const DURATION_DEBUG = 10000;

export const topToBottomTransition = (baseEl: HTMLElement, opts?: any) => {
  try {
    if (opts.direction === 'forward') {
      return createAnimation()
        .addElement(opts.enteringEl)
        .duration(DURATION)
        .iterations(1)
        .easing('ease-in')
        .fromTo('transform', 'translateY(-100%)', 'translateY(0)')
        .fromTo('opacity', '0.5', '1');
    } else {
      return createAnimation()
        .addElement(opts.leavingEl)
        .duration(DURATION)
        .iterations(1)
        .easing('ease-out')
        .fromTo('transform', 'translateY(0)', 'translateY(100%)')
        .fromTo('opacity', '1', '0.5');
    }
  } catch (error) {
    return createAnimation();
  }
};

export const pageTransitionAnimations = (baseEl: HTMLElement, opts?: any) => {
  try {
    if (opts.direction === 'forward') {
      const enteringAnimation = createAnimation()
        .addElement(opts.enteringEl)
        .duration(DURATION)
        .easing('cubic-bezier(0.3, 0, 0.66, 1)')
        .fromTo('transform', 'translateX(100%)', 'translateX(0)')
        .fromTo('opacity', 0.8, 1);

      return createAnimation().addAnimation(enteringAnimation);
    } else {
      return createAnimation()
        .addElement(opts.leavingEl)
        .duration(DURATION)
        .iterations(1)
        .easing('ease-out')
        .fromTo('transform', 'translateX(0vw)', 'translateX(130vw)')
        .fromTo('opacity', '0.75', '0.0');
    }
  } catch (error) {
    return createAnimation();
  }
};

export const slideAnimation = trigger('slideAnimation', [
  transition('* <=> *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          padding: '1rem',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ transform: 'translateX(100%)', opacity: 0.8 })], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0)', opacity: 1 })], {
      optional: true,
    }),
    group([
      query(
        ':leave',
        [
          animate(
            `${DURATION}ms 50ms cubic-bezier(0.3, 0, 0.66, 1)`,
            style({ transform: 'translateX(-100%)', opacity: 0.8 }),
          ),
        ],
        { optional: true },
      ),
      query(
        ':enter',
        [
          animate(
            `${DURATION}ms cubic-bezier(0.3, 0, 0.66, 1)`,
            style({ transform: 'translateX(0)', opacity: 1 }),
          ),
        ],
        { optional: true },
      ),
    ]),
  ]),
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    group([
      query(
        ':leave',
        [animate(`${DURATION_DEBUG}ms ease-out`, style({ opacity: 0 }))],
        { optional: true },
      ),
      query(
        ':enter',
        [animate(`${DURATION_DEBUG}ms ease-out`, style({ opacity: 1 }))],
        { optional: true },
      ),
    ]),
  ]),
]);
