import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { FirebaseModule } from './firebase/firebase.module';
import { ConnectState } from './stores/connect.store';
import { InGameState } from './stores/in-game.store';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { ChallengeState } from './stores/challenge.store';
import { MissionState } from './stores/mission.store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  ModuleTranslateLoader,
  IModuleTranslationOptions,
} from '@larscom/ngx-translate-module-loader';
import { ChatState } from '../chat/store/chat.store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimerService, UpdateService } from 'common';
import { SyncService } from './services/sync.service';
import { environment } from '../../environments/environment';

export function moduleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';

  const options: IModuleTranslationOptions = {
    lowercaseNamespace: true,
    modules: [
      // final url: ./assets/i18n/chat/en.json
      { baseTranslateUrl, moduleName: 'chat' },
      { baseTranslateUrl, moduleName: 'connect' },
      { baseTranslateUrl, moduleName: 'game' },
      { baseTranslateUrl, moduleName: 'common' },
    ],
  };

  return new ModuleTranslateLoader(http, options);
}

@NgModule({
  declarations: [],
  providers: [TimerService, SyncService, UpdateService],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: moduleHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot(
      [ConnectState, InGameState, ChallengeState, MissionState, ChatState],
      {
        developmentMode: false, //TODO: activate
      },
    ),
    // NgxsStoragePluginModule.forRoot({
    //   storage: StorageOption.SessionStorage,
    // }),
    NgxsLoggerPluginModule.forRoot({
      filter: (action, state) =>
        !action.constructor.type?.includes('@@') &&
        !action?.constructor.type?.includes('[Forms]'),
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Freddy',
      disabled: environment.production,
    }),
    FirebaseModule,
    IonicStorageModule.forRoot({
      name: '__freddy',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
  ],
})
export class CoreModule {}
